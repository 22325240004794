"use client";

/**
 * Third-party libraries.
 */
import { Call } from "@twilio/voice-sdk";
import { Button, Tooltip } from "antd";
import { useCallback, useMemo, useState } from "react";

/**
 * Project components.
 */
import {
  CommunicationDirection,
  CommunicationLogStatus,
  useCommunicationLogContext,
} from "@/components/client/communication-log";
import { CommunicationLog } from "@/components/client/communication-log/types";
import { Icon } from "@/components/client/icon";
import { ASSET_ROUTE } from "@/components/common/route";
import { StringUtility } from "@/components/common/string";
import { useTwilioContext } from "../twilio";

/**
 * Communication log card properties.
 */
export type CommunicationLogCardProps = {
  /**
   * Data to display on the card.
   */
  data: CommunicationLog;
  /**
   * Callback when the card is clicked.
   */
  onClick?: (args: {
    communicationLog: CommunicationLog;
  }) => void | Promise<void>;
};

/**
 * A single entry in the communication log list.
 */
export const CommunicationLogCard = ({
  data,
  onClick,
}: CommunicationLogCardProps) => {
  const {
    agentName,
    callSid,
    clientPhoneNumber,
    direction,
    from,
    id,
    status,
    time,
    to,
  } = data;

  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const {
    communicationLogsActiveLoading,
    communicationLogsConcludedLoading,
    selectedCommunicationLog,
  } = useCommunicationLogContext();

  const acceptCallMutation =
    status === CommunicationLogStatus.RINGING &&
    direction === CommunicationDirection.INBOUND
      ? data.acceptCallMutation
      : null;

  const hangUpCallMutation =
    status === CommunicationLogStatus.RINGING ||
    status === CommunicationLogStatus.ONGOING
      ? data.hangUpCallMutation
      : null;

  const { isMuted, toggleMute } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * Indicates that the call accept service is loading.
   */
  const acceptingCall = acceptCallMutation?.[1].loading;

  /**
   * Indicates that the hang up call service is loading.
   */
  const hangingUpCall = hangUpCallMutation?.[1].loading;

  /**
   * The icon to display based on the status of the communication.
   */
  const icon = useMemo(() => {
    switch (status) {
      case CommunicationLogStatus.CANCELED:
      case CommunicationLogStatus.DECLINED:
      case CommunicationLogStatus.COMPLETED:
      case CommunicationLogStatus.WRAPPING_UP:
        return "phone-down";
      case CommunicationLogStatus.FAILED:
      case CommunicationLogStatus.MISSED:
      case CommunicationLogStatus.NO_RESPONSE:
      case CommunicationLogStatus.ONGOING:
      case CommunicationLogStatus.PENDING:
      case CommunicationLogStatus.RINGING:
        return "phone";
      default:
        throw new Error(`Unknown status: ${status}`);
    }
  }, [status]);

  /**
   * The colors to display based on the status of the communication.
   * This includes the following:
   * - Background color of the card
   * - Icon color
   * - Text color
   */
  const color = useMemo(() => {
    /**
     * Indicates that this communication log is actively selected.
     */
    const isSelected = selectedCommunicationLog?.id === id;

    switch (status) {
      case CommunicationLogStatus.PENDING:
      case CommunicationLogStatus.RINGING:
      case CommunicationLogStatus.WRAPPING_UP:
        return {
          background: isSelected ? "bg-neutral-grey" : "bg-sky-100",
          border:
            status === CommunicationLogStatus.RINGING
              ? "border-l-4 border-solid border-tpl-blue"
              : undefined,
          icon: "!text-tpl-navy",
          text: "",
        };
      case CommunicationLogStatus.ONGOING:
        return {
          background: "bg-semantic-green",
          border: undefined,
          icon: "!text-white",
          text: "text-white",
        };
      case CommunicationLogStatus.COMPLETED:
        return {
          background: isSelected ? "bg-neutral-grey" : "bg-white",
          icon: "!text-semantic-green",
          text: "",
        };
      case CommunicationLogStatus.CANCELED:
      case CommunicationLogStatus.DECLINED:
      case CommunicationLogStatus.FAILED:
      case CommunicationLogStatus.MISSED:
      case CommunicationLogStatus.NO_RESPONSE:
        return {
          background: isSelected ? "bg-neutral-grey" : "bg-white",
          border: undefined,
          icon: "!text-semantic-red",
          text: "",
        };
      default:
        throw new Error(`Unknown status: ${status}`);
    }
  }, [id, selectedCommunicationLog?.id, status]);

  /**
   * Active Twilio call associated with this communication log card.
   *
   * This could be null if there is no active call.
   *
   * Active call means the call is connected to the Twilio server.
   */
  const [twilioCall, setCall] = useState<Call | null>(null);

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  const handleOnClick = useCallback(async () => {
    onClick?.({ communicationLog: data });
  }, [data, onClick]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <div
      className={`flex items-center justify-start gap-2 self-stretch p-4 ${color.background} animate-slide-left ${color.text} cursor-pointer ${color.border}`}
      onClick={handleOnClick}
    >
      <Icon src={icon} className={`!text-2xl ${color.icon}`} />
      <div className="flex shrink grow basis-0 flex-col items-start justify-center gap-2">
        <div className="flex flex-col self-stretch">
          <div className="flex items-center justify-between self-stretch">
            <div
              className={`communication-log-card-status text-slate-600 ${color.text} text-sm font-semibold leading-[17.50px]`}
            >
              <div>{clientPhoneNumber}</div>
            </div>
            <div
              className={`communication-log-card-time text-xs font-normal leading-none text-gray-400 ${
                status === CommunicationLogStatus.RINGING ||
                status === CommunicationLogStatus.ONGOING
                  ? "hidden"
                  : ""
              } ${color.text} `}
            >
              {time}
            </div>
          </div>
          <div
            className={`communication-log-card-contact-name shrink grow basis-0 text-gray-400 ${color.text} text-sm leading-[17.50px]`}
          >
            {status} ({direction})
          </div>
        </div>
        <div className="flex w-full items-center justify-start gap-1">
          <div
            className={
              "flex h-4 w-4 items-center justify-center rounded-[100px] bg-slate-100"
            }
          >
            <div className="relative h-4 w-4">
              <div
                className={`communication-log-card-agent-initials absolute left-[3px] top-[3px] text-center text-[8px] font-semibold leading-[10.40px] text-slate-600`}
              >
                {StringUtility.getInitials({
                  input: agentName,
                  maxLength: 2,
                })}
              </div>
            </div>
          </div>
          <div
            className={`communication-log-card-agent-name text-slate-600 ${
              color.text
            } ${
              agentName?.length ? "" : "!text-semantic-red"
            } max-w-44 overflow-hidden text-ellipsis text-nowrap text-xs font-semibold leading-none`}
          >
            {agentName?.length ? agentName : "Unassigned"}
          </div>
        </div>
        {status === CommunicationLogStatus.RINGING && (
          <audio src={ASSET_ROUTE.AUDIO.CALL_RINGTONE} autoPlay loop />
        )}
      </div>
      <div className="flex items-start justify-start gap-2">
        {status === CommunicationLogStatus.ONGOING && (
          <Tooltip
            title="Accept"
            style={{
              display:
                status === CommunicationLogStatus.ONGOING ? "block" : "none",
            }}
          >
            <Button
              icon={
                <Icon
                  src={
                    isMuted({
                      callSid: data.callSid,
                    })
                      ? "microphone-mute"
                      : "microphone"
                  }
                />
              }
              onClick={(event) => {
                event.stopPropagation();

                toggleMute({
                  callSid: data.callSid,
                });
              }}
              shape="circle"
              style={{
                height: 40,
                width: 40,
              }}
              type="default"
            />
          </Tooltip>
        )}
        {direction === CommunicationDirection.INBOUND &&
          status === CommunicationLogStatus.RINGING && (
            <Tooltip title="Accept">
              <Button
                className="!text-white"
                disabled={
                  acceptingCall ||
                  hangingUpCall ||
                  communicationLogsActiveLoading ||
                  communicationLogsConcludedLoading
                }
                icon={<Icon src="phone" className="!text-white" />}
                loading={acceptingCall}
                onClick={(event) => {
                  acceptCallMutation?.[0]({
                    variables: {
                      input: {
                        callSid,
                      },
                    },
                  });
                }}
                shape="circle"
                style={{
                  backgroundColor: "var(--semantic-green)",
                  height: 40,
                  width: 40,
                }}
                type="primary"
              />
            </Tooltip>
          )}
        {(status === CommunicationLogStatus.RINGING ||
          status === CommunicationLogStatus.ONGOING) && (
          <Tooltip
            title={
              status === CommunicationLogStatus.RINGING
                ? direction === CommunicationDirection.INBOUND
                  ? "Reject"
                  : "Hang Up"
                : "End"
            }
          >
            <Button
              disabled={
                acceptingCall ||
                hangingUpCall ||
                communicationLogsActiveLoading ||
                communicationLogsConcludedLoading
              }
              icon={<Icon src="phone-down" className="!text-white" />}
              loading={hangingUpCall}
              onClick={(event) => {
                event.stopPropagation();

                console.log("jayvee", {
                  hangUpCallMutation,
                });

                hangUpCallMutation?.[0]({
                  variables: {
                    input: {
                      callSid,
                    },
                  },
                });
              }}
              shape="circle"
              style={{
                backgroundColor: "var(--semantic-red)",
                height: 40,
                width: 40,
              }}
              type="primary"
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};
