/**
 * Project components.
 */
import {
  CommunicationDirection,
  CommunicationLogInboundCallStatusMapper,
  CommunicationLogOutboundCallStatusMapper,
  CommunicationLogStatus,
} from "@/components/client/communication-log/enumerations";
import {
  CommunicationLog,
  CommunicationLogBase,
  CommunicationLogInboundRinging,
  CommunicationLogOnGoing,
  CommunicationLogOther,
  CommunicationLogOutboundRinging,
  CommunicationLogsGroupedByDate,
} from "@/components/client/communication-log/types";
import { DateUtility } from "@/components/client/date";
import {
  Call,
  CallDirection,
  CallRecording,
  CallRouting,
  CallsConcludedQuery,
  CallStatus,
  CallsUserActiveQuery,
  useCallAcceptMutation,
  useCallCancelMutation,
  useCallRejectMutation,
  UserProfile,
} from "@/components/client/graphql";
import { TwilioContext } from "../../twilio";

/**
 * Internal call record.
 */
type InternalCall = Pick<
  Call,
  | "callSid"
  | "date"
  | "dateEnded"
  | "dateStarted"
  | "direction"
  | "duration"
  | "from"
  | "id"
  | "status"
  | "summary"
  | "to"
> & {
  /**
   * Call routings.
   */
  routings?: {
    /**
     * Unique identifier for the routing entry.
     */
    id: string;
    /**
     * The date and time the entry was routed to the agent.
     */
    date: string;
    /**
     * The status of the routing.
     */
    status: CallRouting["status"];
    /**
     * The user who handled the call.
     */
    user?: {
      /**
       * The user profile.
       */
      profile: {
        /**
         * The agent name who handled the call.
         */
        fullName: string;
      };
    };
  }[];
  /**
   * The user who handled the call.
   */
  user?: {
    /**
     * The user profile.
     */
    profile?: {
      /**
       * The agent name who handled the call.
       */
      fullName?: UserProfile["fullName"];
    };
  } | null;
  /**
   * The recording object of the call. Contains the `recordingUrl`.
   */
  recording?: Pick<
    CallRecording,
    "recordingUrl" | "synced" | "thirdPartyUrl" | "status"
  > | null;
};

/**
 * Arguments for the `fromCall` function.
 */
type FromCallArgs = {
  // ===========================================================================
  // Mapper data.
  // ===========================================================================
  /**
   * Call record to map to a communication log.
   */
  call: InternalCall;
  // ===========================================================================
  // Callback functions and states for the communication log.
  // ===========================================================================
  /**
   * Apollo GraphQL mutation for accepting the call.
   *
   * Use this to accept an incoming call.
   */
  acceptCallMutation: ReturnType<typeof useCallAcceptMutation>;
  /**
   * Apollo GraphQL mutation for canceling the call.
   *
   * Use this to cancel an outgoing call.
   */
  cancelCallMutation: ReturnType<typeof useCallCancelMutation>;
  /**
   * Apollo GraphQL mutation for rejecting the call.
   *
   * Use this to reject an incoming call.
   */
  rejectCallMutation: ReturnType<typeof useCallRejectMutation>;

  // ===========================================================================
  // Twilio functions.
  // ===========================================================================
  /**
   * Get a Twilio call record.
   *
   * @returns Twilio call instance you can use to end, or mute an ongoing call.
   */
  getTwilioCall: TwilioContext["getCall"];
};

/**
 * Maps a "Call" record to a "CommunicationLog" record.
 */
export function fromCall({
  call,
  acceptCallMutation,
  cancelCallMutation,
  getTwilioCall,
  rejectCallMutation,
}: FromCallArgs): CommunicationLog {
  if (!call) {
    throw new Error("Call is required.");
  }

  /**
   * Direction of the call.
   */
  const direction =
    call.direction === CallDirection.Inbound
      ? CommunicationDirection.INBOUND
      : CommunicationDirection.OUTBOUND;

  /**
   * Phone number of the customer.
   */
  const clientPhoneNumber =
    call.direction === CallDirection.Inbound ? call.from : call.to;

  const status =
    CommunicationLogInboundCallStatusMapper[
      call.status as keyof typeof CommunicationLogInboundCallStatusMapper
    ];

  const baseCommunicationLog: CommunicationLogBase = {
    agentName: call.user?.profile?.fullName ?? "",
    callSid: call.callSid!,
    clientPhoneNumber,
    date: new Date(call.date),
    dateEnded: call.dateEnded ? new Date(call.dateEnded) : undefined,
    dateStarted: call.dateStarted ? new Date(call.dateStarted) : undefined,
    direction,
    duration: call.duration ?? 0,
    from: call.from!,
    id: call.id!,
    routings:
      call.routings?.map((routing) => ({
        id: routing.id,
        agentName: routing.user?.profile.fullName!,
        date: new Date(routing.date),
        status: routing.status,
      })) ?? [],
    summary: call.summary ?? undefined,
    time: DateUtility.getTime({ date: new Date(call.date) }),
    to: call.to!,
    recording: call.recording ?? undefined,
  };

  /**
   * Inbound call that is ringing.
   *
   * Attach the `accepting` and `hangingUp` states and callback functions.
   */
  if (
    call.direction === CallDirection.Inbound &&
    call.status === CallStatus.Queued
  ) {
    return {
      ...baseCommunicationLog,
      status: CommunicationLogStatus.RINGING,
      acceptCallMutation,
      hangUpCallMutation: rejectCallMutation,
    } as CommunicationLogInboundRinging;
  }

  /**
   * Inbound call that is ongoing.
   */
  if (call.status === CallStatus.InProgress) {
    return {
      ...baseCommunicationLog,
      status: CommunicationLogStatus.ONGOING,
      hangUpCallMutation: [
        (args: {
          variables: {
            input: {
              callSid: string;
            };
          };
        }) => {
          /**
           * Twilio call instance. This controls the call using the Twilio SDK.
           */
          const twilioCall = getTwilioCall({
            callSid: call.callSid,
          });

          twilioCall?.disconnect();
        },
        { loading: false },
      ],
    } as CommunicationLogOnGoing;
  }

  /**
   * Outgoing call that is ringing.
   */
  if (
    call.direction === CallDirection.Outbound &&
    call.status === CallStatus.Queued
  ) {
    return {
      ...baseCommunicationLog,
      status: CommunicationLogStatus.RINGING,
      hangUpCallMutation: cancelCallMutation,
    } as CommunicationLogOutboundRinging;
  }

  return {
    ...baseCommunicationLog,
    status: (call.direction === CallDirection.Inbound
      ? CommunicationLogInboundCallStatusMapper[
          call.status as keyof typeof CommunicationLogInboundCallStatusMapper
        ]
      : CommunicationLogOutboundCallStatusMapper[
          call.status as keyof typeof CommunicationLogOutboundCallStatusMapper
        ]) as CommunicationLogOther["status"],
  } satisfies CommunicationLog;
}

/**
 * Arguments for the `fromCallsGroupedByDate` function.
 */
type FromCallsGroupedByDateArgs = {
  /**
   * TODO: This must not be referring to the query directly. Create own type.
   *
   * Calls to map to communication logs.
   */
  calls:
    | CallsConcludedQuery["callsConcluded"]
    | CallsUserActiveQuery["callsUserActive"];
} & Pick<
  FromCallArgs,
  "acceptCallMutation" | "cancelCallMutation" | "rejectCallMutation"
> &
  Pick<FromCallArgs, "getTwilioCall">;

/**
 * Maps the calls to communication logs.
 */
export function fromCallsGroupedByDate({
  calls,
  acceptCallMutation,
  cancelCallMutation,
  getTwilioCall,
  rejectCallMutation,
}: FromCallsGroupedByDateArgs) {
  const _communicationLog: CommunicationLogsGroupedByDate = [];

  /**
   * Adds the communication log to a communication log group.
   * If the group does not exist, it will be created.
   */
  function addCommunicationLogToGroup({
    call,
    groupLabel,
  }: {
    /**
     * The call to add to the group.
     */
    call:
      | CallsUserActiveQuery["callsUserActive"]["items"][0]
      | CallsConcludedQuery["callsConcluded"]["items"][0];
    /**
     * Label of the group to add the communication log to.
     * If this group does not exist, it will be created.
     */
    groupLabel: string;
  }) {
    /**
     * Index of the communication log group for matching the given group label.
     */
    let communicationLogGroupIndex = _communicationLog.findIndex(
      (log) => log.label === groupLabel,
    );

    // There is no existing communication log group for the given group label.
    if (communicationLogGroupIndex === -1) {
      // Create a new communication log group.
      _communicationLog.push({
        label: groupLabel,
        logs: [],
      });

      // Push the communication log to the newly created group.
      communicationLogGroupIndex = _communicationLog.findIndex(
        (log) => log.label === groupLabel,
      );
    }

    /**
     * Communication log created from the call record.
     */
    const communicationLog = fromCall({
      call,
      acceptCallMutation,
      cancelCallMutation,
      rejectCallMutation,
      getTwilioCall,
    });

    // Add the communication log to the group.
    if (communicationLog) {
      _communicationLog[communicationLogGroupIndex].logs.push(communicationLog);
    }
  }

  calls.items.forEach((call) => {
    /**
     * The date the call was initialized.
     */
    const callDate = new Date(call.date);

    // Get today's communication logs.
    if (DateUtility.isToday({ date: callDate })) {
      addCommunicationLogToGroup({ call, groupLabel: "Today" });
    }

    // Get yesterday's comminication logs.
    else if (DateUtility.isYesterday({ date: callDate })) {
      addCommunicationLogToGroup({ call, groupLabel: "Yesterday" });
    }

    // Get communication logs older than yesterday.
    else {
      addCommunicationLogToGroup({
        call,
        // Use the date as the group label.
        groupLabel: DateUtility.getDate({ date: callDate }),
      });
    }
  });
  return _communicationLog;
}
