"use client";

/**
 * Third-party libraries.
 */
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

/**
 * Project components.
 */
import { applicationConfiguration } from "@/application-configuration";
import { useAuthenticationContext } from "@/components/client/authentication";
import { CallUtility } from "@/components/client/call/utilities";
import {
  CommunicationDirection,
  CommunicationLogStatus,
} from "@/components/client/communication-log";
import {
  CommunicationLog,
  CommunicationLogsGroupedByDate,
} from "@/components/client/communication-log/types";
import { CommunicationLogUtility } from "@/components/client/communication-log/utilities";
import { useApplicationContext } from "@/components/client/context";
import {
  CallsConcludedQuery,
  CallStatus,
  CallsUserActiveQuery,
  useCallAcceptMutation,
  useCallCancelMutation,
  useCallEventSubscription,
  useCallLazyQuery,
  useCallRejectMutation,
  useCallsConcludedQuery,
  useCallsUserActiveQuery,
} from "@/components/client/graphql";
import { useNotificationContext } from "@/components/client/notification";
import { useTwilioContext } from "@/components/client/twilio";

/**
 * Communication Log context.
 */
type CommunicationLogContext = {
  // ===========================================================================
  // Communication Logs Active
  // ===========================================================================
  /**
   * Active communication logs.
   *
   * These are logs which are currently being handled by the agent.
   */
  communicationLogsActive: CommunicationLogsGroupedByDate;
  /**
   * Indicates that the active communication logs are loading.
   */
  communicationLogsActiveLoading: boolean;
  /**
   * Indicates that the active communication logs are being refetched.
   */
  communicationLogsActiveFetchingMore: boolean;
  /**
   * Fetches more active communication logs.
   */
  fetchMoreCommunicationLogsActive: () => Promise<void>;
  /**
   * Indicates that there are more active communication logs to fetch.
   */
  hasMoreCommunicationLogsActive: boolean;
  // ===========================================================================
  // Communication Logs Concluded
  // ===========================================================================
  /**
   * Communication logs that have been concluded.
   *
   * These are logs which have already marked as concluded.
   * This includes cancelled, completed, failed, missed, and rejected calls.
   */
  communicationLogsConcluded: CommunicationLogsGroupedByDate;
  /**
   * Indicates that the communication logs are loading.
   */
  communicationLogsConcludedLoading: boolean;
  /**
   * Indicates that the communication logs are being refetched.
   */
  communicationLogsConcludedFetchingMore: boolean;
  /**
   * Fetches more communication logs.
   */
  fetchMoreCommunicationLogsConcluded: () => Promise<void>;
  /**
   * Indicates that there are more communication logs to fetch.
   */
  hasMoreCommunicationLogsConcluded: boolean;
  // ===========================================================================
  // Selected Communication Log
  // ===========================================================================
  /**
   * Selected communication log.
   * This is the currently active communication log from the communication log list.
   */
  selectedCommunicationLog: CommunicationLog | null;
  /**
   * Indicates that the selected communication log is loading.
   */
  selectedCommunicationLogLoading: boolean;
  /**
   * Sets the selected communication log.
   * This causes the other panels to display corresponding information in respect
   * of the active communication log record.
   */
  setSelectedCommunicationLog: (
    communicationLog: CommunicationLog | null,
  ) => void;
};

/**
 * Statuses of a call that is considered active.
 */
const CALL_STATUSES_ACTIVE = [
  CallStatus.InProgress,
  CallStatus.Queued,
  CallStatus.WrappingUp,
];

/**
 * Statuses of a call that are considered concluded.
 */
const CALL_STATUSES_CONCLUDED = [
  CallStatus.Canceled,
  CallStatus.Completed,
  CallStatus.Failed,
  CallStatus.Missed,
  CallStatus.Rejected,
];

/**
 * Communication Log related context.
 */
const CommunicationLogContext = React.createContext<CommunicationLogContext>({
  // ===========================================================================
  // Communication Log Active
  // ===========================================================================
  communicationLogsActive: [],
  communicationLogsActiveLoading: true,
  communicationLogsActiveFetchingMore: false,
  fetchMoreCommunicationLogsActive: async () => {},
  hasMoreCommunicationLogsActive: true,
  // ===========================================================================
  // Communication Log Concluded
  // ===========================================================================
  communicationLogsConcluded: [],
  communicationLogsConcludedLoading: true,
  communicationLogsConcludedFetchingMore: false,
  fetchMoreCommunicationLogsConcluded: async () => {},
  hasMoreCommunicationLogsConcluded: true,
  // ===========================================================================
  // Selected Communication Log
  // ===========================================================================
  selectedCommunicationLog: null,
  selectedCommunicationLogLoading: true,
  setSelectedCommunicationLog: () => {},
});

/**
 * Use Communication Log context.
 */
export const useCommunicationLogContext = () => {
  return React.useContext(CommunicationLogContext);
};

/**
 * Communication log context provider.
 */
export const CommunicationLogContextProvider = ({
  children,
}: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  /**
   * Application context.
   */
  const { mockData } = useApplicationContext();

  const { user } = useAuthenticationContext();

  const { notification } = useNotificationContext();

  const { getCall: getTwilioCall } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const acceptCallMutation = useCallAcceptMutation();
  const rejectCallMutation = useCallRejectMutation();
  const cancelCallMutation = useCallCancelMutation();

  /**
   * Active calls query.
   * Active calls are calls that are in the following statuses:
   * - Queued (Assigned to the current user)
   * - In Progress
   * - Wrapping Up
   */
  const {
    data: callsUserActiveData,
    networkStatus: callsActiveNetworkStatus,
    updateQuery: updateCallsUserActiveQuery,
  } = useCallsUserActiveQuery({
    variables: {
      limit: applicationConfiguration.pagination.limit,
      offset: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  /**
   * Concluded calls query.
   * This fetches the concluded calls in the system.
   *
   * Concluded calls are calls that are in final statuses listed as follows:
   * - Canceled
   * - Completed
   * - Failed
   * - Missed
   * - Rejected
   */
  const {
    data: callsConcludedData,
    fetchMore: fetchMoreCallsConcluded,
    loading: loadingCallsConcluded,
    networkStatus: callsConcludedNetworkStatus,
    updateQuery: updateCallsConcludedQuery,
  } = useCallsConcludedQuery({
    variables: {
      limit: applicationConfiguration.pagination.limit,
      offset: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  /**
   * Retrieves the details of a single call.
   */
  const [getCall] = useCallLazyQuery();

  /**
   * Subscribe to call events.
   */
  useCallEventSubscription({
    variables: {
      input: {
        userId: user?.id,
      },
    },
    onComplete() {
      console.log("useCallEventSubscription onComplete");
    },
    onError(error) {
      console.error("Error subscribing to call event", error);
    },
    /**
     * Updates the active and concluded calls when there is a call event.
     */
    onData({ client, data }) {
      const newCall = data.data?.callEvent;

      if (!newCall) return;

      /**
       * Update the active calls data when there is a call event.
       */
      updateCallsUserActiveQuery((previousData) => {
        if (!data.data) return previousData;

        const newCall = data.data.callEvent;

        /**
         * Nothing to do if there is no call in the published event.
         */
        if (!newCall) return previousData;

        /**
         * Index of the existing call in the calls agent list matching the
         * call in the ublished event.
         */
        const existingCallIndex = previousData.callsUserActive.items.findIndex(
          (call) => call.id === newCall.id,
        );

        /**
         * Indicates whether the call exists in the existing calls agent list.
         */
        const isCallExisting = existingCallIndex > -1;

        /**
         * Indicates that the user can view the call.
         */
        const isCallUserViewable = CallUtility.isUserViewable({
          call: {
            status: newCall.status,
            userId: newCall.user?.id,
          },
          user: user!,
        });

        /**
         * This will hold the updated list of agent calls.
         */
        const updatedCalls = [...previousData.callsUserActive.items];

        /**
         * A generated key used for tagging a notification with a unique identifier.
         */
        const notificationKey = `notification-call-${newCall.id}`;

        // Call is viewable by the user.
        if (
          isCallUserViewable &&
          CALL_STATUSES_ACTIVE.includes(newCall.status)
        ) {
          // Update the existing call since it already exists in the list.
          if (isCallExisting) {
            updatedCalls[existingCallIndex] = newCall;
          }
          // Add the new call on top of the list since it doesn't exist.
          else {
            updatedCalls.unshift(newCall);

            // /**
            //  * Show a notification for the incoming call.
            //  */
            // if (CALL_STATUSES_ACTIVE.includes(newCall.status)) {
            //   // Show a notification for the incoming call.
            //   notification.open({
            //     closable: false,
            //     description: (
            //       <CommunicationLogCard
            //         data={CommunicationLogUtility.fromCall({
            //           call: newCall,
            //           acceptCallMutation,
            //           cancelCallMutation,
            //           getTwilioCall,
            //           rejectCallMutation,
            //         })}
            //       />
            //     ),
            //     duration: 0,
            //     key: notificationKey,
            //     message: "Incoming Call",
            //   });
            // }
          }

          if (
            // Auto select a new call in the active call section.
            !isCallExisting ||
            /**
             * Update the selected communication log if it is the same call.
             * This is to ensure the call details are up to date.
             */
            selectedCommunicationLog?.id === newCall.id
          ) {
            _setSelectedCommunicationLog(
              CommunicationLogUtility.fromCall({
                acceptCallMutation,
                call: newCall,
                cancelCallMutation,
                getTwilioCall,
                rejectCallMutation,
              }),
            );

            /**
             * Remove the notification if the call is no longer ringing.
             */
            if (newCall.status !== CallStatus.Queued) {
              // Remove the notification.
              notification.destroy(notificationKey);
            }
          }
        }
        // Call is not viewable by the user.
        else if (isCallExisting) {
          // Remove the call from the list.
          updatedCalls.splice(existingCallIndex, 1);

          // Close the notification if it is removed.
          notification.destroy(notificationKey);
        }

        // Sort updatedCallsAgent from newest to oldest date.
        updatedCalls.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB.getTime() - dateA.getTime();
        });

        return Object.assign({} as CallsUserActiveQuery, previousData, {
          callsUserActive: {
            ...previousData.callsUserActive,
            items: updatedCalls,
          },
        });
      });

      /**
       * Update the concluded calls data when there is a call event.
       */
      updateCallsConcludedQuery((previousData) => {
        if (!data.data) return previousData;

        const newCall = data.data.callEvent;

        /**
         * Nothing to do if there is no call in the published event.
         */
        if (!newCall) return previousData;

        /**
         * Index of the existing call in the calls agent list matching the
         * call in the ublished event.
         */
        const existingCallIndex = previousData.callsConcluded.items.findIndex(
          (call) => call.id === newCall.id,
        );

        /**
         * Indicates whether the call exists in the existing calls agent list.
         */
        const isCallExisting = existingCallIndex > -1;

        /**
         * Indicates that the user can view the call.
         */
        const isCallUserViewable = CallUtility.isUserViewable({
          call: {
            status: newCall.status,
            userId: newCall.user?.id,
          },
          user: user!,
        });

        /**
         * This will hold the updated list of agent calls.
         */
        const updatedCallsAgent = [...previousData.callsConcluded.items];

        // Call is viewable by the user.
        if (
          isCallUserViewable &&
          CALL_STATUSES_CONCLUDED.includes(newCall.status)
        ) {
          // Update the existing call since it already exists in the list.
          if (isCallExisting) {
            updatedCallsAgent[existingCallIndex] = newCall;
          }
          // Add the new call on top of the list since it doesn't exist.
          else {
            updatedCallsAgent.unshift(newCall);
          }

          // Also update the selected communication log if it is the same call.
          if (selectedCommunicationLog?.id === newCall.id) {
            _setSelectedCommunicationLog(
              CommunicationLogUtility.fromCall({
                acceptCallMutation,
                call: newCall,
                cancelCallMutation,
                getTwilioCall,
                rejectCallMutation,
              }),
            );
          }
        }
        // Call is not viewable by the user.
        else if (isCallExisting) {
          // Remove the call from the list.
          updatedCallsAgent.splice(existingCallIndex, 1);
        }

        // Sort updatedCallsAgent from newest to oldest date.
        updatedCallsAgent.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB.getTime() - dateA.getTime();
        });

        return Object.assign({} as CallsConcludedQuery, previousData, {
          callsConcluded: {
            ...previousData.callsConcluded,
            items: updatedCallsAgent,
          },
        });
      });
    },
  });

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * Indicates that there are more communication logs to fetch.
   * The count excludes any ongoing calls.
   * The count for determining if there are more communication logs is based only
   * on the active calls (call status equal to queued, in progress, or wrapping
   * up that is assigned to the user).
   */
  const hasMoreCommunicationLogsActive = useMemo<
    CommunicationLogContext["hasMoreCommunicationLogsActive"]
  >(() => {
    // There are no active calls.
    if (
      !callsUserActiveData?.callsUserActive.totalCount ||
      !callsUserActiveData?.callsUserActive.items.length
    ) {
      return false;
    }

    /**
     * We are only counting the active calls for determining if there are
     * more communication logs.
     */
    return (
      (callsUserActiveData?.callsUserActive.totalCount || 0) >
      (callsUserActiveData?.callsUserActive.items.length || 0)
    );
  }, [callsUserActiveData]);

  /**
   * Indicates that there are more communication logs to fetch.
   * The count excludes any ongoing calls.
   * The count for determining if there are more communication logs is based only
   * on the concluded calls (call status not equal to pending, queued, in progress, or wrapping up).
   */
  const hasMoreCommunicationLogsConcluded = useMemo<
    CommunicationLogContext["hasMoreCommunicationLogsConcluded"]
  >(() => {
    // There are no concluded calls.
    if (
      !callsConcludedData?.callsConcluded.totalCount ||
      !callsConcludedData?.callsConcluded.items.length
    ) {
      return false;
    }

    /**
     * We are only counting the concluded calls for determining if there are
     * more communication logs.
     */
    return (
      (callsConcludedData?.callsConcluded.totalCount || 0) >
      (callsConcludedData?.callsConcluded.items.length || 0)
    );
  }, [callsConcludedData]);

  /**
   * The current offset for fetching more communication logs.
   */
  const [paginationOffset, setPaginationOffset] = useState<number>(
    applicationConfiguration.pagination.offset,
  );

  /**
   * Indicates which communication log is currently active on the screen.
   * This could control which subpanels are shown.
   */
  const [selectedCommunicationLog, setSelectedCommunicationLog] =
    useState<CommunicationLog | null>(null);

  /**
   * Indicates that the selected communication log is loading.
   */
  const [selectedCommunicationLogLoading, setSelectedCommunicationLogLoading] =
    useState<CommunicationLogContext["selectedCommunicationLogLoading"]>(true);

  // ===========================================================================
  // ===========================================================================
  // Variables
  // ===========================================================================
  // ===========================================================================

  /**
   * The communication log records in the system.
   */
  const communicationLogsActive =
    useMemo<CommunicationLogsGroupedByDate>(() => {
      const groupLabel = "Active";

      const _communicationLogs: CommunicationLogsGroupedByDate = [
        {
          label: groupLabel,
          logs: [],
        },
      ];

      // There are no calls.
      if (!callsUserActiveData?.callsUserActive.items.length) {
        return _communicationLogs;
      }

      /**
       * Comminication logs grouped by date.
       * This starts as empty and is being populated by the "addCommunicationLogToGroup" function.
       */
      const logs = callsUserActiveData.callsUserActive.items.map((call) =>
        CommunicationLogUtility.fromCall({
          acceptCallMutation,
          call,
          cancelCallMutation,
          getTwilioCall,
          rejectCallMutation,
        }),
      );

      return [
        {
          label: groupLabel,
          logs,
        },
      ];
    }, [
      acceptCallMutation,
      callsUserActiveData,
      cancelCallMutation,
      getTwilioCall,
      rejectCallMutation,
    ]);

  /**
   * The communication log records in the system.
   */
  const communicationLogsConcluded =
    useMemo<CommunicationLogsGroupedByDate>(() => {
      if (mockData) {
        return [
          {
            label: "Today",
            logs: [
              {
                id: "5",
                agentName: "John Doe",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Doe",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "John Doe",
                status: CommunicationLogStatus.MISSED,
                time: "10:30 AM",
                to: "Jane Doe",
              },
              {
                id: "6",
                agentName: "Jake Doe",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Josh Doe",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "Jake Doe",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Josh Doe",
              },
              {
                id: "7",
                agentName: "John Smith",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Smith",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "John Smith",
                status: CommunicationLogStatus.MISSED,
                time: "3:30 PM",
                to: "Jane Smith",
              },
              {
                id: "8",
                agentName: "Alice Johnson",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Bob Johnson",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "Alice Johnson",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Bob Johnson",
              },
              {
                id: "9",
                agentName: "John Doe",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Doe",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "John Doe",
                status: CommunicationLogStatus.MISSED,
                time: "10:30 AM",
                to: "Jane Doe",
              },
              {
                id: "10",
                agentName: "Jake Doe",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Josh Doe",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "Jake Doe",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Josh Doe",
              },
              {
                id: "11",
                agentName: "John Smith",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Smith",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "John Smith",
                status: CommunicationLogStatus.MISSED,
                time: "3:30 PM",
                to: "Jane Smith",
              },
            ],
          },
          {
            label: "Yesterday",
            logs: [
              {
                id: "12",
                agentName: "John Smith",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Smith",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "John Smith",
                status: CommunicationLogStatus.MISSED,
                time: "3:30 PM",
                to: "Jane Smith",
              },
              {
                id: "13",
                agentName: "Alice Johnson",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Bob Johnson",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "Alice Johnson",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Bob Johnson",
              },
              {
                id: "14",
                agentName: "John Doe",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Doe",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "John Doe",
                status: CommunicationLogStatus.MISSED,
                time: "10:30 AM",
                to: "Jane Doe",
              },
              {
                id: "15",
                agentName: "Jake Doe",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Josh Doe",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "Jake Doe",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Josh Doe",
              },
              {
                id: "16",
                agentName: "John Smith",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Smith",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "John Smith",
                status: CommunicationLogStatus.MISSED,
                time: "3:30 PM",
                to: "Jane Smith",
              },
              {
                id: "17",
                agentName: "Alice Johnson",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Bob Johnson",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "Alice Johnson",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Bob Johnson",
              },
            ],
          },
          {
            label: "Two Days Ago",
            logs: [
              {
                id: "18",
                agentName: "John Doe",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Doe",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "John Doe",
                status: CommunicationLogStatus.MISSED,
                time: "10:30 AM",
                to: "Jane Doe",
              },
              {
                id: "19",
                agentName: "Jake Doe",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Josh Doe",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "Jake Doe",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Josh Doe",
              },
              {
                id: "20",
                agentName: "John Smith",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Smith",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "John Smith",
                status: CommunicationLogStatus.MISSED,
                time: "3:30 PM",
                to: "Jane Smith",
              },
              {
                id: "21",
                agentName: "Alice Johnson",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Bob Johnson",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "Alice Johnson",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Bob Johnson",
              },
              {
                id: "22",
                agentName: "John Doe",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Doe",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "John Doe",
                status: CommunicationLogStatus.MISSED,
                time: "10:30 AM",
                to: "Jane Doe",
              },
              {
                id: "23",
                agentName: "Jake Doe",
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Josh Doe",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "Jake Doe",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Josh Doe",
              },
            ],
          },
        ];
      }

      // There are no calls.
      if (!callsConcludedData?.callsConcluded.items.length) {
        return [
          {
            label: "Today",
            logs: [],
          },
        ];
      }

      /**
       * Comminication logs grouped by date.
       * This starts as empty and is being populated by the "addCommunicationLogToGroup" function.
       */
      const _communicationLog: CommunicationLogsGroupedByDate =
        CommunicationLogUtility.fromCallsGroupedByDate({
          acceptCallMutation,
          calls: callsConcludedData.callsConcluded,
          cancelCallMutation,
          getTwilioCall,
          rejectCallMutation,
        });

      return _communicationLog;
    }, [
      acceptCallMutation,
      callsConcludedData,
      cancelCallMutation,
      getTwilioCall,
      mockData,
      rejectCallMutation,
    ]);

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /**
   * Fetch more calls.
   */
  const _fetchMoreCallsConcluded = useCallback(async () => {
    // Do nothing if the communication logs are loading.
    if (loadingCallsConcluded) {
      return;
    }

    /**
     * New pagination offset to be used for fetching more records.
     */
    let newPaginationOffset =
      paginationOffset + applicationConfiguration.pagination.limit;

    if (paginationOffset === 0) {
      newPaginationOffset = applicationConfiguration.pagination.limit;
    }

    // Fetch more calls when the communication logs limit changes.
    fetchMoreCallsConcluded({
      variables: {
        // Use the current call size as the next offset for fetching more calls.
        offset: paginationOffset + applicationConfiguration.pagination.limit,
      },
      updateQuery(previousData, { fetchMoreResult }) {
        /**
         * A list of the calls viewable by the agent.
         * Fetch more results are added or updated in this list.
         */
        const updatedCallsAgent = [...previousData.callsConcluded.items];

        /**
         * IDs of the existing calls in the list. Initialize this with the
         * current existing call IDs.
         */
        const existingCallIds =
          previousData?.callsConcluded?.items.map((call) => call.id) ?? [];

        /**
         * Update the existing calls with the new calls or add the new call if
         * it doesn't exist in the existing calls.
         */
        fetchMoreResult.callsConcluded.items.forEach((newCall) => {
          /**
           * Index of the existing call in the calls agent list matching the
           * call in the fetch more result.
           *
           * @example
           * // A -1 value when it doesn't have a match
           * -1
           *
           * @example
           * // Greater than -1 value when it has a match
           * 0
           */
          const existingCallIndex = existingCallIds.indexOf(newCall.id);

          /**
           * Indicates whether the call exists in the existing calls agent list.
           */
          const existingCall = existingCallIndex > -1;

          /**
           * Indicates that the user can view the call.
           */
          const isCallUserViewable = CallUtility.isUserViewable({
            call: {
              status: newCall.status,
              userId: newCall.user?.id,
            },
            user: user!,
          });

          // Call is viewable by the user.
          if (isCallUserViewable) {
            // Call is existing in the call list.
            if (existingCall) {
              // Replace the existing call with the new call.
              updatedCallsAgent[existingCallIndex] = newCall;
            }
            // Call is not existing in the call list.
            else {
              // Add the call in the existing call list.
              existingCallIds.push(newCall.id);

              // Add the call in the updated call list.
              updatedCallsAgent.push(newCall);
            }
          }
          // Call is not viewable by the user.
          else {
            // Remove the call from the list.
            existingCallIds.splice(existingCallIndex, 1);

            // Remove the call from the list.
            updatedCallsAgent.splice(existingCallIndex, 1);
          }
        });

        if (
          fetchMoreResult.callsConcluded.offset <
          fetchMoreResult.callsConcluded.totalCount
        ) {
          /**
           * Keep track of the current offset for the next fetch more operation.
           */
          setPaginationOffset(fetchMoreResult.callsConcluded.offset);
        }

        return Object.assign({}, previousData, {
          callsConcluded: {
            items: updatedCallsAgent,
            limit: fetchMoreResult.callsConcluded.limit,
            offset: fetchMoreResult.callsConcluded.offset,
            totalCount: fetchMoreResult.callsConcluded.totalCount,
          },
        });
      },
    });
  }, [fetchMoreCallsConcluded, loadingCallsConcluded, paginationOffset, user]);

  /**
   * Sets the selected communication log.
   */
  const _setSelectedCommunicationLog: CommunicationLogContext["setSelectedCommunicationLog"] =
    useCallback(
      (communicationLog) => {
        setSelectedCommunicationLogLoading(true);

        if (!communicationLog) {
          setSelectedCommunicationLog(communicationLog);
          return;
        }

        getCall({
          variables: {
            filter: {
              id: communicationLog.id,
            },
          },
          onCompleted: (data) => {
            if (!data.call) {
              return;
            }

            setSelectedCommunicationLog(
              CommunicationLogUtility.fromCall({
                acceptCallMutation,
                call: data.call,
                cancelCallMutation,
                getTwilioCall,
                rejectCallMutation,
              }),
            );

            setSelectedCommunicationLogLoading(false);
          },
          onError: (error) => {
            setSelectedCommunicationLogLoading(false);
          },
        });
      },
      [
        acceptCallMutation,
        cancelCallMutation,
        getCall,
        getTwilioCall,
        rejectCallMutation,
      ],
    );

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the selected communication log when the call list changes.
   * - Removes it if it is not included anymore on the updated list. This might
   * happen when the call is reassigned to a different agent.
   * - Update the status if the call is still in the list.
   */
  useEffect(() => {
    /**
     * Indicates whether the selected communication log exists in the communication logs.
     *
     * This is used to determine whether the selected communication log should be reset.
     *
     * A selected communication log might not be valid in the communication logs with status
     * queued because it might have been reassigned to a different agent.
     */
    let selectedCommunicationLogExists = false;

    for (const call of callsUserActiveData?.callsUserActive.items ?? []) {
      if (selectedCommunicationLog?.id !== call.id) {
        continue;
      }

      selectedCommunicationLogExists = true;

      break;
    }

    /**
     * Check if the existing selected communication log is still in the list after
     * a refetch.
     *
     * Reset the selected communication log so we can make sure the status
     * is up to date.
     */
    for (const call of callsConcludedData?.callsConcluded.items ?? []) {
      if (selectedCommunicationLogExists) {
        break;
      }

      if (selectedCommunicationLog?.id !== call.id) {
        continue;
      }

      selectedCommunicationLogExists = true;

      break;
    }

    /**
     * Clear the selected communication log so we can remove any panels
     * that are associated with the communication log if:
     * - There are no communication logs.
     * - The selected communication log doesn't exist anymore in the list. This
     * might happen in the case of a queued call that was reassigned to a different agent.
     */
    if (!selectedCommunicationLogExists) {
      _setSelectedCommunicationLog(null);
    }
  }, [
    callsConcludedData?.callsConcluded,
    selectedCommunicationLog?.id,
    _setSelectedCommunicationLog,
    callsUserActiveData?.callsUserActive.items,
  ]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <CommunicationLogContext.Provider
      value={{
        // =====================================================================
        // Communication Logs Active
        // =====================================================================
        communicationLogsActive,
        communicationLogsActiveFetchingMore: callsActiveNetworkStatus === 3,
        communicationLogsActiveLoading: callsActiveNetworkStatus === 1,
        fetchMoreCommunicationLogsActive: async () => {
          alert("TODO: fetchMoreCommunicationLogsActive not yet implemented.");
        },
        hasMoreCommunicationLogsActive,
        // =====================================================================
        // Communication Logs Concluded
        // =====================================================================
        communicationLogsConcluded,
        communicationLogsConcludedFetchingMore:
          callsConcludedNetworkStatus === 3,
        communicationLogsConcludedLoading: callsConcludedNetworkStatus === 1,
        fetchMoreCommunicationLogsConcluded: _fetchMoreCallsConcluded,
        hasMoreCommunicationLogsConcluded,
        // =====================================================================
        // Selected Communication Log
        // =====================================================================
        selectedCommunicationLog,
        selectedCommunicationLogLoading,
        setSelectedCommunicationLog: _setSelectedCommunicationLog,
      }}
    >
      {children}
    </CommunicationLogContext.Provider>
  );
};
